import { createBrowserRouter } from "react-router-dom";
import Error from "./pages/Error";
import AuthLayout from "./components/Layouts/AuthLayout";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import App from "./pages/Main";
import MainLayout from "./components/Layouts/MainLayout";
import Bookings from "./pages/bookings/bookings";
import Book from "./pages/bookings/book";
import Cars from "./pages/cars/cars";
import Locations from "./pages/locations/Locations";
import NewLocations from "./pages/locations/newLocation";
import NewCar from "./pages/cars/new";
import { Help } from "./pages/help/help";
import About from "./pages/about/About";
import Confirm from "./pages/bookings/confirm";
import Packages from "./pages/packages/packages";
import BuyPackage from "./pages/packages/buy/buyPackage";

export const router = createBrowserRouter([
  {
    path: "/auth/login",
    element: (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
  },
  {
    path: "/auth/register",
    element: (
      <AuthLayout>
        <Register />
      </AuthLayout>
    ),
  },
  {
    path: "/",
    element: (
      <MainLayout>
        <App />
      </MainLayout>
    ),
  },

  {
    path: "/help",
    element: (
      <MainLayout>
        <Help />
      </MainLayout>
    ),
  },
  {
    path: "/about",
    element: (
      <MainLayout>
        <About />
      </MainLayout>
    ),
  },
  {
    path: "/bookings",
    element: (
      <MainLayout>
        <Bookings />
      </MainLayout>
    ),
  },
  {
    path: "/bookings/confirm",
    element: (
      <MainLayout>
        <Confirm />
      </MainLayout>
    ),
  },
  {
    path: "/packages",
    element: (
      <MainLayout>
        <Packages />
      </MainLayout>
    ),
  },
  {
    path: "/packages/buy",
    element: (
      <MainLayout>
        <BuyPackage />
      </MainLayout>
    ),
  },
  {
    path: "/bookings/book",
    element: (
      <MainLayout>
        <Book />
      </MainLayout>
    ),
  },
  {
    path: "/bookings/confirm",
    element: (
      <MainLayout>
        <Confirm />
      </MainLayout>
    ),
  },
  {
    path: "/cars",
    element: (
      <MainLayout>
        <Cars />
      </MainLayout>
    ),
  },
  {
    path: "/cars/new",
    element: (
      <MainLayout>
        <NewCar />
      </MainLayout>
    ),
  },
  {
    path: "/locations",
    element: (
      <MainLayout>
        <Locations />
      </MainLayout>
    ),
  },
  {
    path: "/locations/new",
    element: (
      <MainLayout>
        <NewLocations />
      </MainLayout>
    ),
  },
  {
    path: "*",
    element: <Error />,
  },
]);
