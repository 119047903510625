/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axiosInstance from "@/lib/axios";
import useCartStore from "@/store/useCartStore";
import { IPayment, Window } from "@/types/types";

const Confirm: React.FC = () => {
  const cart = useCartStore();
  const navigate = useNavigate();
  const [cookies] = useCookies(["jwt"]);

  const handlePaymentCompletion = useCallback(
    async (payment: IPayment) => {
      if (!cart.date || !cart.time) {
        return;
      }

      const [hours, minutes] = cart.time.split(":");
      const dateTime = new Date(cart.date);
      dateTime.setHours(parseInt(hours));
      dateTime.setMinutes(parseInt(minutes));
      cart.setDate(dateTime);

      if (payment.status === "initiated" || payment.status === "paid") {
        const chosenDate = new Date(cart.date);
        const [hours, minutes] = cart.time.split(":");
        chosenDate.setHours(+hours + 3);
        chosenDate.setMinutes(parseInt(minutes));
        console.log(chosenDate.toISOString());
        try {
          await axiosInstance.post("/booking", {
            paymentId: payment.id,
          });
        } catch (error) {
          console.error("Error creating booking: ", error);
          // Handle error (e.g., show error message to user)
        }
      }
    },
    [cart],
  );

  const initializeMoyasar = useCallback(() => {
    const windows = window as Window;
    if (!windows?.Moyasar) return;

    const total: number = cart.totalAfterDiscount || cart.servicePrice || 0;
    if (!cart.date || !cart.time) {
      return;
    }

    const chosenDate = new Date(cart.date);
    const [hours, minutes] = cart.time.split(":");
    chosenDate.setHours(+hours + 3);
    chosenDate.setMinutes(parseInt(minutes));
    console.log(chosenDate.toISOString());
    try {
      windows.Moyasar.init({
        element: ".mysr-form",
        amount: 100 * total,
        currency: "SAR",
        description: "Bluewash",
        publishable_api_key: import.meta.env.VITE_MOYASSER_API_KEY,
        callback_url: `${import.meta.env.MODE === "development" ? "http://localhost:5173/bookings" : `${import.meta.env.VITE_HOST_URL}/bookings`}`,
        methods: ["creditcard", "applepay", "stcpay"],
        metadata: {
          bookingDate: chosenDate.toISOString(),
          carId: cart.carId,
          locationId: cart.locationId,
          serviceId: cart.serviceId,
          couponCode: cart.coupon,
          token: cookies.jwt,
        },
        apple_pay: {
          country: "SA",
          label: "Bluewash",
          validate_merchant_url: "https://api.moyasar.com/v1/applepay/initiate",
        },
        on_completed: handlePaymentCompletion,
      });
    } catch (error) {
      console.error("Error initializing Moyasar:", error);
      // Handle error (e.g., show error message to user)
    }
  }, [cart, cookies.jwt, handlePaymentCompletion]);

  useEffect(() => {
    if (
      !cart.servicePrice ||
      !cart.serviceId ||
      !cart.locationId ||
      !cart.date
    ) {
      navigate("/bookings/book");
      return;
    }

    initializeMoyasar();
  }, [cart, navigate, initializeMoyasar]);

  if (!cart.serviceId || !cart.locationId || !cart.date || !cart.servicePrice) {
    console.log("Missing cart data:", {
      serviceId: cart.serviceId,
      locationId: cart.locationId,
      date: cart.date,
      servicePrice: cart.servicePrice,
    });
    navigate("/bookings/book");
    return null;
  }

  return (
    <div className="p-8">
      <div className="mysr-form"></div>
    </div>
  );
};

export default Confirm;
