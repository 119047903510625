import { handleZodError } from "@/helpers/zod-error-handler";
import axiosInstance from "../lib/axios";
import { IPackages } from "@/types/types";

export const getAllPackages = async () => {
  try {
    const response = await axiosInstance.get<IPackages[]>("/package");

    return response.data;
  } catch (error) {
    handleZodError(error);
  }
};
