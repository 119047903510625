import { BookingStatus } from "@/types/types";

interface StatusProps {
  status: BookingStatus;
}

const Status = ({ status }: StatusProps) => {
  if (status === BookingStatus.Initiated) {
    return (
      <span className="rounded bg-gray-500 p-2 font-bold text-white shadow-lg">
        بانتظار الموافقة
      </span>
    );
  }

  if (status === BookingStatus.InProgress) {
    return (
      <span className="rounded bg-yellow-500 p-2 font-bold text-white shadow-lg">
        قيد التنفيذ
      </span>
    );
  }

  if (status === BookingStatus.Cancelled) {
    return (
      <span className="rounded bg-red-500 p-2 font-bold text-white shadow-lg">
        ملغى
      </span>
    );
  }

  if (status === BookingStatus.Completed) {
    return (
      <span className="rounded bg-green-500 p-2 font-bold text-white shadow-lg">
        مكتمل
      </span>
    );
  }
  if (status === BookingStatus.Scheduled) {
    return (
      <span className="rounded bg-green-500 p-2 font-bold text-white shadow-lg">
        مجدول
      </span>
    );
  }

  return null;
};

export default Status;
